import BgImageStripComponent from '@wix/thunderbolt-elements/src/components/BgImageStrip/viewer/BgImageStrip';


const BgImageStrip = {
  component: BgImageStripComponent
};


export const components = {
  ['BgImageStrip']: BgImageStrip
};


// temporary export
export const version = "1.0.0"
